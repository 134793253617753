//COMPONENTS
import Trans from "./Trans.js";
//UTILS
import { textFrequencyFromHs, getCurrencyText } from "../utilities/Utils.js";
import { intraComCountries } from "../datas/vat";

function QuoteTotal({ values, deal, lineItems, company, expirationDate }) {
  var recurringDiscount = 0; //'$662.00'
  var recurringSubtotal = 0; //'$3,314.00'
  var oneTimeDiscount = 0;
  var oneTimeSubtotal = 0; //'$1,006.00'
  var vat = 0;
  var total = 0; //'$4,320.00'
  var depositPercentage = 100.0;
  var depositAmount = 0.0;

  var recurringFrequency = ""; //'/ year'
  var currencyCode = "";

  for (var lineItem of lineItems) {
    //Currency
    currencyCode = lineItem.hs_line_item_currency_code;

    //Recurring :
    if (lineItem.recurringbillingfrequency != null) {
      //Discount
      if (lineItem.discount !== null) {
        recurringDiscount =
          recurringDiscount +
          Number(lineItem.quantity) * Number(lineItem.discount);
      } else if (lineItem.hs_discount_percentage != null) {
        recurringDiscount =
          recurringDiscount +
          (Number(lineItem.quantity) *
            Number(lineItem.hs_discount_percentage) *
            Number(lineItem.price)) /
          100.0;
      }

      //Subtotal
      recurringSubtotal = recurringSubtotal + Number(lineItem.amount);
      //Frequency
      recurringFrequency = lineItem.recurringbillingfrequency;
    }
    //One-Shot
    else {
      //Discount
      oneTimeDiscount =
        oneTimeDiscount + Number(lineItem.quantity) * Number(lineItem.discount);
      oneTimeSubtotal = oneTimeSubtotal + Number(lineItem.amount);
    }
  }

  //TVA
  if (deal && values) {
    if (
      getTVArequested(
        values.billingCountry.value,
        values.billingTVA.value,
        deal.pays
      )
    ) {
      vat = (recurringSubtotal + oneTimeSubtotal) * 0.2;
    }
  } else if (deal) {
    if (getTVArequested(company.pays__livraison_, company.numero_de_tva_intra_communautaire__facturation_, deal.pays)) {
      vat = (recurringSubtotal + oneTimeSubtotal) * 0.2;
    }
  }

  //Total
  total = recurringSubtotal + oneTimeSubtotal + vat;

  //Deposit
  if (deal && deal.percentage_deposit) {
    if (deal.percentage_deposit == 33.0) {
      depositPercentage = deal.percentage_deposit;
      depositAmount = total / 3.0;
    }
    else if (deal.percentage_deposit > 0.0) {
      depositPercentage = deal.percentage_deposit;
      depositAmount = (total * depositPercentage) / 100;
    }
  }

  const recurringDiscountDiv = (
    <div className="media private-media is--text--success">
      <div className="private-media__item private-media__item--left">
        <Trans text="quote_total_recurring_discount"></Trans>
      </div>
      <div className="media-body private-media__body">
        <hr className="guide m-top-3"></hr>
      </div>
      <div className="private-media__item private-media__item--right">
        <div
          direction="column"
          wrap="nowrap"
          className="UIFlex__StyledFlex-gi5hfq-0 jqQEbZ private-flex text-right"
        >
          <div>
            <span
              data-locale-at-render="en"
              data-key="quotes.recurrence.annually"
            >
              <span>
                <span>{getCurrencyText(recurringDiscount, currencyCode)}</span>
              </span>
              <span> {textFrequencyFromHs(recurringFrequency)}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  const recurringSubtotalDiv = (
    <div className="media private-media">
      <div className="private-media__item private-media__item--left">
        <Trans text="quote_total_recurring_subtotal"></Trans>
      </div>
      <div className="media-body private-media__body">
        <hr className="guide m-top-3"></hr>
      </div>
      <div className="private-media__item private-media__item--right">
        <div
          direction="column"
          wrap="nowrap"
          className="UIFlex__StyledFlex-gi5hfq-0 jqQEbZ private-flex text-right"
        >
          <div>
            <span
              data-locale-at-render="en"
              data-key="quotes.recurrence.annually"
            >
              <span>
                <span>{getCurrencyText(recurringSubtotal, currencyCode)}</span>
              </span>
              <span> {textFrequencyFromHs(recurringFrequency)}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  const oneTimeDiscountDiv = (
    <div className="media private-media is--text--success">
      <div className="private-media__item private-media__item--left">
        <Trans text="quote_total_onetime_discount"></Trans>
      </div>
      <div className="media-body private-media__body">
        <hr className="guide m-top-3"></hr>
      </div>
      <div className="private-media__item private-media__item--right">
        <span>
          <span>
            <span>{getCurrencyText(oneTimeDiscount, currencyCode)}</span>
          </span>
        </span>
      </div>
    </div>
  );

  const oneTimeSubtotalDiv = (
    <div className="media private-media">
      <div className="private-media__item private-media__item--left">
        <Trans text="quote_total_onetime_subtotal"></Trans>
      </div>
      <div className="media-body private-media__body">
        <hr className="guide m-top-3"></hr>
      </div>
      <div className="private-media__item private-media__item--right">
        <span>
          <span>
            <span>{getCurrencyText(oneTimeSubtotal, currencyCode)}</span>
          </span>
        </span>
      </div>
    </div>
  );

  const divStyle = { color: "rgb(255, 0, 0)" };

  const vatDiv = (
    <div>
      <div className="media private-media">
        <div className="private-media__item private-media__item--left">
          <Trans text="quote_total_vat"></Trans>
        </div>

        <div className="media-body private-media__body">
          <hr className="guide m-top-3"></hr>
        </div>
        <div className="private-media__item private-media__item--right">
          <span>
            <span>
              <span>{getCurrencyText(vat, currencyCode)}</span>
            </span>
          </span>
        </div>
      </div>
      <div>
        <p style={divStyle}>
          <Trans text="user_forms_billing_vatnumber_subtitle"></Trans>
        </p>
      </div>
    </div>
  );

  const totalNoVATDiv = (
    <div className="media private-media">
      <div className="private-media__item private-media__item--left">
        <h5 className="m-bottom-0 display-inline">
          <strong>
            <Trans text="quote_total_total_novat"></Trans>
          </strong>
        </h5>
      </div>
      <div className="media-body private-media__body">
        <hr className="guide m-top-3"></hr>
      </div>
      <div className="private-media__item private-media__item--right">
        <div className="quote-price-total">
          <h5 className="m-bottom-0 display-inline">
            <strong>
              <span>
                <span>
                  {getCurrencyText(
                    recurringSubtotal + oneTimeSubtotal,
                    currencyCode
                  )}
                </span>
              </span>
            </strong>
          </h5>
          <br></br>
        </div>
      </div>
    </div>
  );

  const totalDiv = (
    <div className="media private-media">
      <div className="private-media__item private-media__item--left">
        <h5 className="m-bottom-0 display-inline">
          <strong>
            <Trans text="quote_total_total"></Trans>
          </strong>
        </h5>
      </div>
      <div className="media-body private-media__body">
        <hr className="guide m-top-3"></hr>
      </div>
      <div className="private-media__item private-media__item--right">
        <div className="quote-price-total">
          <h5 className="m-bottom-0 display-inline">
            <strong>
              <span>
                <span>{getCurrencyText(total, currencyCode)}</span>
              </span>
            </strong>
          </h5>
          <br></br>
        </div>
      </div>
    </div>
  );

  const depositDiv = (
    <div className="media private-media" >
      <div className="private-media__item private-media__item--left">
        <div>
          <p style={divStyle}>
            <Trans text="quote_deposit"></Trans>
          </p>
        </div>
      </div>
      <div className="media-body private-media__body">
        <hr className="guide m-top-3"></hr>
      </div>
      <div className="private-media__item private-media__item--right">
        <div className="quote-price-total">
          <h5 className="m-bottom-0 display-inline">
            <strong>
              <span>
                <span>{getCurrencyText(depositAmount, currencyCode)}</span>
              </span>
            </strong>
          </h5>
          <br></br>
        </div>
      </div>
    </div>
  );

  const expirationDateDiv = (
    <p className="text-center m-y-8 is--heading-7">
      <i18n-string
        data-locale-at-render="en"
        data-key="quotes.expired.expirationDate"
        data-selenium-test="quotes-expired-at-text"
      >
        {expirationDate != null && expirationDate !== "" && (
          <div>
            <Trans text="quote_total_expiration"></Trans>{" "}
            {new Intl.DateTimeFormat("en-GB", {
              year: "numeric",
              month: "long",
              day: "2-digit",
            }).format(new Date(expirationDate) ?? new Date())}
            .
          </div>
        )}
      </i18n-string>
    </p>
  );

  return (
    <div>
      <div className="quote-price-summary undefined">
        {recurringDiscount === 0 ? <div></div> : recurringDiscountDiv}
        {recurringSubtotal === 0 ? <div></div> : recurringSubtotalDiv}
        {oneTimeDiscount === 0 ? <div></div> : oneTimeDiscountDiv}
        {oneTimeSubtotal === 0 ? <div></div> : oneTimeSubtotalDiv}
        {vat === 0 ? <div></div> : vatDiv}
        {totalNoVATDiv}
        {totalDiv}
        {depositAmount > 0 ? depositDiv : <div></div>}
      </div>

      {/* Expire le ... */}
      {expirationDate === null ? <p></p> : expirationDateDiv}
    </div>
  );
}

function getTVArequested(
  pays__livraison_,
  numero_de_tva_intra_communautaire__facturation_,
  dealCountry
) {
  var country = pays__livraison_ ? pays__livraison_ : dealCountry;

  if (country == null) {
    return true;
  }

  let isIntracom = intraComCountries.includes(country);
  let noNumber =
    numero_de_tva_intra_communautaire__facturation_ == null ||
    numero_de_tva_intra_communautaire__facturation_ === "";
  if (country === "France") {
    return true;
  } else if (isIntracom) {
    if (noNumber) {
      return true;
    } else {
      return false;
    }
  }
}

export default QuoteTotal;
