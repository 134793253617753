import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useParams } from "react-router";
//CSS
import "../styles/App.css";
//COMPONENTS
import Page from "./Page.js";
import Billing from "./Billing.js";
import AlbionConfigurator from "./AlbionConfigurator.js";

function Deal() {
  let { dealId } = useParams();
  return <Page dealId={dealId.replace(/[\u200B-\u200D\uFEFF]/g, "")}></Page>;
}

function Bill() {
  let { customerStripeId } = useParams();
  return <Billing customerStripeId={customerStripeId}></Billing>;
}

function Albion() {
  return <AlbionConfigurator />;
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/billing/:customerStripeId" element={<Bill />} />
        <Route path="/:dealId" element={<Deal />} />
        <Route path="/albion" element={<Albion />} />
      </Routes>
    </Router>
  );
}

export default App;
