import React, { useState, useEffect } from "react";
import { MenuItem, FormControl, Select, InputLabel, Container } from "@mui/material";
import * as XLSX from "xlsx";

const sanitizeString = (str) => (typeof str === "string" ? str.replace(/"/g, "") : str);

const DynamicForm = ({ excelData }) => {
    const [formFields, setFormFields] = useState([]);
    const [options, setOptions] = useState({});
    const [selectedValues, setSelectedValues] = useState({});
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (!excelData) return;

        const workbook = XLSX.read(excelData, { type: "binary" });
        const dataSheet = XLSX.utils.sheet_to_json(workbook.Sheets["DATA"], { header: 1 });

        let fields = [];
        let initialOptions = {};

        for (let i = 0; i < dataSheet[0].length; i += 2) {
            const fieldName = dataSheet[0][i];
            fields.push(fieldName);
            initialOptions[fieldName] = dataSheet.slice(1).map(row => sanitizeString(row[i] ?? "")).filter(Boolean);
        }

        setFormFields(fields);
        setOptions({ [fields[0]]: initialOptions[fields[0]], [fields[1]]: initialOptions[fields[1]] });
    }, [excelData]);

    const handleSelectionChange = (event, field, index) => {
        const value = event.target.value;
        setSelectedValues(prev => ({ ...prev, [field]: value }));
        setCurrentIndex(index)
    };

    useEffect(() => {
        if (excelData == null) { return }

        const workbook = XLSX.read(excelData, { type: "binary" });
        const associationSheet = XLSX.utils.sheet_to_json(workbook.Sheets["ASSOCIATION"], { header: 1 });

        let nextField = null;
        let sheetName = null;
        let relevantFields = [];

        associationSheet.forEach(row => {
            if (row[0]?.endsWith(formFields[currentIndex + 1])) {
                nextField = row[0].split(" - ").pop();
                sheetName = row[1];
                relevantFields = (row[0].split(" - "));
                relevantFields.pop()
            }
        });

        console.log(nextField, sheetName)
        if (nextField && sheetName) {
            const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

            let filterCriteria = currentIndex < 2 ? Object.entries(selectedValues) : Object.entries(selectedValues).filter(([key]) => relevantFields.includes(key));
            let relevantRows = sheetData.slice(1).filter(row => {
                return filterCriteria.every(([key, val]) => {
                    let colIndex = sheetData[0].indexOf(key);
                    return colIndex !== -1 && sanitizeString(row[colIndex]) === val;
                });
            });

            let nextFieldIndex = sheetData[0].indexOf(nextField);
            console.log(relevantRows)
            console.log(nextField)
            console.log(nextFieldIndex)
            let newOptions = relevantRows.map(row => sanitizeString(row[nextFieldIndex]));
            console.log(newOptions)

            setOptions(prev => ({ ...prev, [nextField]: [...new Set(newOptions)] }));
        }
    }, [selectedValues])

    return (
        <Container>
            {formFields.map((field, index) => (
                <FormControl fullWidth margin="normal" key={field} disabled={index > 0 && !selectedValues[formFields[index - 1]]}>
                    <InputLabel>{field}</InputLabel>
                    <Select
                        value={selectedValues[field] || ""}
                        onChange={(e) => handleSelectionChange(e, field, index)}
                    >
                        {options[field]?.map((option, idx) => (
                            <MenuItem key={idx} value={option}>{option}</MenuItem>
                        )) ?? <MenuItem key={0} value={"-"}>{"-"}</MenuItem>}
                    </Select>
                </FormControl>
            ))}
        </Container>
    );
};

const AlbionConfigurator = () => {
    const [excelData, setExcelData] = useState(null);

    useEffect(() => {
        fetch("/myData.xlsx")
            .then(response => response.arrayBuffer())
            .then(data => setExcelData(data))
            .catch(error => console.error("Erreur de chargement du fichier Excel :", error));
    }, []);

    return <DynamicForm excelData={excelData} />;
};

export default AlbionConfigurator;
